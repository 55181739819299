import ERR_CODE from "./apiErrorCode";
import { getLabel } from "./exceptions/duplicatedFields";
import { toTitleCase } from "@/utils/string";

function getContextFromResponse(error) {
    switch (error.response?.data?.exceptionCode) {
        case ERR_CODE.DUPLICATED_FIELD:
            var duplicatedLabel = getLabel(error.response.data.context[0]);
            return {
                title: `Duplicated ${toTitleCase(duplicatedLabel)}`,
                message: `The ${duplicatedLabel} is already taken. Please try with another ${duplicatedLabel}`
            };

        default:
            return {
                title: "Error",
                message: "An unexpected error occured. Please try again later."
            };
    }
}

class ExceptionMessage {
    title;
    message;

    constructor(title, message) {
        this.title = title;
        this.message = message;
    }

    static fromError(error) {
        let { title, message } = getContextFromResponse(error);
        return new ExceptionMessage(title, message);
    }

    getTitle() {
        return this.title;
    }

    getMessage() {
        return this.message;
    }

    getContext() {
        return {
            title: this.title,
            message: this.message
        };
    }
}

export default ExceptionMessage;
