var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tel-input fd-validation",class:{ error: _vm.hasError }},[_c('label',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()]),_c('vue-tel-input',{ref:"telInput",attrs:{"value":_vm.value,"autoFormat":false,"autoDefaultCountry":false,"defaultCountry":"MY","mode":"national","dropdownOptions":{
      showSearchBox: true,
      showDialCodeInSelection: true,
      showDialCodeInList: true,
      showFlags: true,
      width: '400px'
    },"inputOptions":{
      placeholder: 'Enter the contact number',
      type: 'tel'
    },"validCharactersOnly":""},on:{"input":_vm.telInput},scopedSlots:_vm._u([{key:"arrow-icon",fn:function(){return [_c('i',{staticClass:"ml-1 fas fa-chevron-down"})]},proxy:true}])}),(_vm.hasError)?_c('p',{staticClass:"fg-danger"},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }