export const DUPLICATE_FIELDS = {
    EMAIL: "email",
    MOBILE_NO: "mobileNumber",
    REN_LICENSE: "renLicense"
};

export function getLabel(field) {
    switch (field) {
        case DUPLICATE_FIELDS.EMAIL:
            return "email";
        case DUPLICATE_FIELDS.MOBILE_NO:
            return "contact number";
        case DUPLICATE_FIELDS.REN_LICENSE:
            return "REN License";

        default:
            return field;
    }
}
