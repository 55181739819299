import ExceptionMessage from "@/utils/error/ExceptionMessage";
import { toTitleCase } from "@/utils/string";

export default function useUserSubmitHandler(
    context,
    { role = "user", successCallback = () => {} }
) {
    async function handleCreateSuccess() {
        context.root.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: `${toTitleCase(role)} is created successfully.`
        });
        successCallback();
    }
    function handleSubmitError(err) {
        let { title, message } = ExceptionMessage.fromError(err).getContext();

        // Toast Error
        context.root.$notify({
            group: "alert",
            type: "error",
            title: title,
            text: message,
            duration: 30000
        });
    }

    return {
        handleCreateSuccess,
        handleSubmitError
    };
}
