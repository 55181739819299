<template>
  <div class="tel-input fd-validation" :class="{ error: hasError }">
    <label class="label">
      {{ label }}
      <span style="color: red" v-if="required">*</span>
    </label>
    <vue-tel-input
      ref="telInput"
      :value="value"
      :autoFormat="false"
      :autoDefaultCountry="false"
      defaultCountry="MY"
      mode="national"
      :dropdownOptions="{
        showSearchBox: true,
        showDialCodeInSelection: true,
        showDialCodeInList: true,
        showFlags: true,
        width: '400px'
      }"
      :inputOptions="{
        placeholder: 'Enter the contact number',
        type: 'tel'
      }"
      validCharactersOnly
      @input="telInput"
    >
      <template #arrow-icon>
        <i class="ml-1 fas fa-chevron-down"></i>
      </template>
    </vue-tel-input>
    <p v-if="hasError" class="fg-danger">{{ errorMessage }}</p>
  </div>
</template>

<script>
import { isEmpty } from "lodash";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

/**
 * I'm wrapper for vue-tel-input: https://vue-tel-input.iamstevendao.com/
 */

export default {
  components: {
    VueTelInput
  },
  mixins: [],
  props: {
    value: {
      type: [String, Number],
      default: ""
    },
    tel: {
      type: Object
    },
    label: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      hasError: false,
      errorMessage: ""
    };
  },
  computed: {},
  watch: {
    value() {
      this.hasError = false;
    }
  },
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    telInput(number, phone) {
      this.$emit("update:tel", {
        mobileNumber: number?.replace(/^0+/, "").toString(),
        mobileCountryCode: phone.countryCallingCode,
        mobileISOCode: phone.countryCode
      });
      this.$emit("input", number);
    },
    setTel(val) {
      this.$refs.telInput.choose(val.mobileISOCode);
      this.$emit("update:tel", {
        mobileNumber: val.mobileNumber.replace(/^0+/, "").toString(),
        mobileCountryCode: val.mobileCountryCode,
        mobileISOCode: val.mobileISOCode
      });
      this.$emit("input", val.mobileNumber);
    },
    validate() {
      let isValid;
      let isPhoneValid = this.$refs.telInput.phoneObject.valid;
      if (isEmpty(this.value)) {
        this.hasError = true;
        this.errorMessage = "This field is required";
        isValid = false;
      } else if (!isPhoneValid) {
        this.hasError = true;
        this.errorMessage = "Phone number is invalid";
        isValid = false;
      }
      return isValid;
    }
  }
};
</script>

<style lang="scss">
.tel-input {
  &.error {
    .vue-tel-input {
      border: solid 2px $color-danger;
    }
  }
}
</style>
